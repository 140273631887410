<template>
  <v-hover v-slot="{ isHovering, props: p }" :disabled="platform.touch">
    <v-card
      v-bind="p"
      :href="value.href"
      rounded="xl"
      style="position: relative; overflow: unset; z-index: 1"
      variant="text"
    >
      <v-slide-y-transition>
        <v-sheet
          v-show="isHovering"
          :class="isHovering ? 'd-flex' : 'd-none'"
          class="pa-8 flex-column"
          color="primary"
          elevation="4"
          rounded="xl"
          style="position: absolute; min-height: 100%; top: 0; width: 100%"
        >
          <p class="text-caption text-uppercase text-center font-weight-bold">
            Learn More About
          </p>
          <h6
            v-if="value.service && value.service.name"
            class="text-h6 text-center text-truncate mb-3"
          >
            {{ value.service.name }}
          </h6>
          <p v-if="value.paragraph" class="text-body-1 mb-2">
            <StyledText :value="value.paragraph" />
          </p>
          <v-icon :icon="mdiArrowRight" class="align-self-end mt-auto" />
        </v-sheet>
      </v-slide-y-transition>

      <div :style="{ visibility: isHovering ? 'hidden' : 'visible' }">
        <v-card-item>
          <v-img
            :aspect-ratio="aspectRatio"
            :sizes="imgResp.sizes"
            :src="imgResp.src"
            :srcset="imgResp.srcset"
            alt="Photo representing this service"
          />
        </v-card-item>

        <v-card-title v-if="value.title" class="text-center">
          <StyledText :value="value.title" />
          <v-icon :icon="mdiArrowRight" />
        </v-card-title>
      </div>
    </v-card>
  </v-hover>
</template>

<script setup>
import { toRef } from 'vue'
import { useDisplay } from 'vuetify'
import { useImgResponsive } from '#root/lib/img'
import { mdiArrowRight } from '@mdi/js'

const props = defineProps({
  value: {
    required: true,
    type: Object
  }
})

const { platform } = useDisplay()
const aspectRatio = 1
const imgResp = useImgResponsive(
  toRef(() => ({
    aspectRatio,
    image: props.value.image,
    sizes: {
      sm: '800px'
    }
  }))
)
</script>
